
<div id="confirmation-dialog-component">
    <kendo-dialog title="{{confirmDialog.title}}" *ngIf="confirmDialog?.opened" (close)="CloseDialog()" [minWidth]="250" [width]="width">
        <p [innerHTML]="confirmDialog.message" style="text-align: left;"></p>
        <div class="d-flex flex-row-reverse">
            <outlined-btn class="no-btn pointer" (click)="close(false)" text="{{confirmDialog.cancelText}}"></outlined-btn>&nbsp;
            <contained-btn class="yes-btn pointer" (click)="close(true)" primary="true" text="{{confirmDialog.confirmText}}"></contained-btn>
        </div>
       
    </kendo-dialog>
  </div>